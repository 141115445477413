.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.containerTopView {
    z-index: 9;
    align-items: flex-start;
    padding-top: 93px; /* header height */
}

.dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 950px;
    border-radius: 23px;
    height: auto;
    text-align: center;
    background: var(--black-color);
    margin: 0 12px;
    border: 1px solid var(--grey-color);
    position: relative;
    color: var(--white-color);
}

.containerTopViewMobile .dialog,
.containerTopView .dialog {
    border-radius: 0 0 23px 23px;
}

.inner {
    width: 100%;
    height: 100%;
    padding: 32px 24px;
    overflow: hidden;
}

.navigation {
    width: 100%;
    padding: 0 24px;
}

.closeModalButton {
    position: absolute;
    right: 15px;
    top: 15px;
}

.closeModalButton > svg {
    width: 40px;
    height: 40px;
}

.closeModalButton:hover {
    opacity: 0.9;
    cursor: pointer;
}

.containerTopViewMobile {
    z-index: 9;
    align-items: flex-start;
    padding-top: 93px; /* header height */
}

@media (min-width: 320px) {
    .dialog {
        width: 100%;
        padding-top: 40px;
    }
}

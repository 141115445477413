@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Inter', sans-serif;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html,
body,
#__next {
    height: 100%;
    font-family: 'Inter', sans-serif;
}

div,
span,
input,
textarea,
select {
    -moz-box-sizing: border-box;
         box-sizing: border-box;
}

body {
    height: 100%;
    width: 100%;
    background-color: #fff;
}

#__next {
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.body-overflow {
    overflow: hidden;
}

.flex-center {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.flex-center-column {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
:root {
    --white-color: #fff;
    --white-color-2: #dddddd;
    --black-color: #000000;
    --darkGrey-color: #1b1b1b;
    --grey-color: #494a50;
    --violet-color: #ae80f0;
    --rose-color: #b87af7;
    --green-color: #b1f9e0;
    --error-color: #b95959;
    --lightGrey-color: #a1a1a1;
    --grey-color-2: #c1c1c1;
    --grey-color-3: #494a51;

    --font-size-60: calc((60 / 16) * 1rem);
    --font-size-48: calc((48 / 16) * 1rem);
    --font-size-32: calc((32 / 16) * 1rem);
    --font-size-30: calc((30 / 16) * 1rem);
    --font-size-24: calc((24 / 16) * 1rem);
    --font-size-20: calc((20 / 16) * 1rem);
    --font-size-18: calc((18 / 16) * 1rem);
    --font-size-16: 1rem;
    --font-size-14: calc((14 / 16) * 1rem);
    --font-size-12: calc((12 / 16) * 1rem);
    --font-size-10: calc((10 / 16) * 1rem);
    --font-size-8: calc((8 / 16) * 1rem);

    --xsmall-font-size: 0.875rem; /* 14px */
    --small-font-size: 1rem; /* 16px */
    --regular-font-size: 1.125rem; /* 18px */
    --xregular-font-size: 1.25rem; /* 20px */
    --medium-font-size: 1.5rem; /* 24px */
    --xmedium-font-size: 2rem; /* 32px */
    --large-font-size: 2.5rem; /* 40px */
    --xlarge-font-size: 3.75rem; /* 60px */
}
.rkl-loading-spinner_spinnerWrapper__3PTjx {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}
.rkl-loading-spinner_spinner__14jcT {
    border: 4px solid var(--black-color);
    border-top: 4px solid var(--white-color);
    border-radius: 50%;
    -webkit-animation: rkl-loading-spinner_spin__2CUDw 1s linear infinite;
            animation: rkl-loading-spinner_spin__2CUDw 1s linear infinite;
    height: 100%;
    width: 100%;
}

@-webkit-keyframes rkl-loading-spinner_spin__2CUDw {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rkl-loading-spinner_spin__2CUDw {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.rkl-button_button__3xaU5 {
    padding: 22px 52px 22px 52px;
    border-radius: 60px;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: 700;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
}

.rkl-button_square__2cuu- {
    width: 68px;
    height: 58px;
    border-radius: 12px;
    white-space: normal;
    padding: 18px 14px;
    font-size: var(--font-size-12);
}

.rkl-button_square__2cuu- span {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.rkl-button_small__1fmcw {
    width: 68px;
    height: 34px;
    border-radius: 45px;
    padding: 8px 11px;
    font-size: var(--font-size-14);
}

.rkl-button_medium__341S7 {
    width: 100px;
    height: 42px;
    border-radius: 45px;
    padding: 8px 11px;
    font-size: var(--font-size-14);
}

.rkl-button_lgDesktop__1Kx23 {
    width: 168px;
    height: 68px;
    padding: 18px 32px;
    border-radius: 40px;
    font-size: var(--font-size-20);
}

.rkl-button_medDesktop__2xdjo {
    width: 145px;
    height: 68px;
    padding: 21px 40px;
    border-radius: 40px;
    font-size: var(--font-size-20);
}

.rkl-button_mobile__3l52_ {
    width: 139px;
    height: 64px;
    font-size: var(--font-size-20);
    padding: 20px 37px;
}

.rkl-button_lgMobile__1iJV3 {
    width: 171px;
    height: 64px;
    padding: 20px 53px;
    font-size: var(--font-size-20);
}

.rkl-button_twitter__iX0sj {
    width: 171px;
    height: 68px;
    padding: 21px 40px;
    font-size: var(--font-size-20);
}

.rkl-button_twitterButtonIcon__MXGJm{
    margin-left: 10px;
    display: -webkit-flex;
    display: flex;
}

.rkl-button_squared__1G3Aw {
    border-radius: 12px;
}

.rkl-button_violetBG__v4IeZ {
    background-color: var(--violet-color);
    color: var(--black-color);
}

.rkl-button_whiteBG__flaei {
    background-color: var(--white-color);
}

.rkl-button_whiteBG__flaei:hover, 
.rkl-button_whiteBG__flaei:active {
    background-color: var(--lightGrey-color) !important;
}

.rkl-button_greenBG__4r3hj {
    background-color: var(--green-color);
    color: var(--black-color);
}

.rkl-button_borderOnlyViolet__2qAIo {
    background-color: transparent;
    color: var(--violet-color);
    border: 1px solid var(--violet-color);
}

.rkl-button_loading__1CkX4 {
    background-color: var(--white-color);
    color: var(--white-color-2);
}

.rkl-button_borderOnlyWhite__2qb6X {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
}

.rkl-button_button__3xaU5:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}

.rkl-button_button__3xaU5:active {
    background-color: var(--white-color);
    color: var(--black-color);
    margin-top: 2px;
    margin-bottom: -2px;
}

.rkl-button_greenBG__4r3hj:hover {
    border: 1px solid var(--white-color);
}

.rkl-button_button__3xaU5:disabled {
    color: black;
    background-color: var(--grey-color);
}

.rkl-button_button__3xaU5:hover:disabled {
    cursor: not-allowed;
}

.rkl-button_button__3xaU5:active:disabled {
    margin-top: 0;
}

.rkl-button_btnSpan__2CuAj {
    display: -webkit-flex;
    display: flex;
    white-space: nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.rkl-button_decorator__2Nm6- {
    padding: 3px 7px;
    border-radius: 40px;
    background-color: var(--black-color);
}

.rkl-button_button__3xaU5:hover:enabled .rkl-button_decorator__2Nm6- {
    color: var(--white-color);
}

.rkl-button_fontColorViolet__TGikR {
    color: var(--violet-color);
}

.rkl-button_fontColorBlack__mze_h {
    color: var(--black-color);
}

.rkl-button_fontColorGreen__16h_k {
    color: var(--green-color);
}

.error-boundary_overlay__29yCY {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.error-boundary_modalDialog__2hyz9 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 800px;
    border-radius: 23px;
    height: auto;
    text-align: center;
    background: var(--black-color);
    margin: 0 12px;
    border: 1px solid var(--grey-color);
    position: relative;
    color: var(--white-color);
}

.error-boundary_modalDialogContent__3jgzr {
    padding: 32px 24px;
}

.error-boundary_header__1Dnpz {
    margin-bottom: 8px;
}

.error-boundary_text__1phjr {
    font-size: var(--xregular-font-size);
    font-weight: bold;
    margin-bottom: 12px;
}

.error-boundary_errorContent__1GH3z {
    background: #1e1e1e;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 23px;
    font-size: 14px;
}

.error-boundary_buttons__IqeyN button {
    margin: 0 5px;
}

.rkl-modal-dialog_container__c_q5X {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.rkl-modal-dialog_containerTopView__avURC {
    z-index: 9;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-top: 93px; /* header height */
}

.rkl-modal-dialog_dialog__2AwQH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    max-width: 950px;
    border-radius: 23px;
    height: auto;
    text-align: center;
    background: var(--black-color);
    margin: 0 12px;
    border: 1px solid var(--grey-color);
    position: relative;
    color: var(--white-color);
}

.rkl-modal-dialog_containerTopViewMobile__3RYqN .rkl-modal-dialog_dialog__2AwQH,
.rkl-modal-dialog_containerTopView__avURC .rkl-modal-dialog_dialog__2AwQH {
    border-radius: 0 0 23px 23px;
}

.rkl-modal-dialog_inner__1lX_X {
    width: 100%;
    height: 100%;
    padding: 32px 24px;
    overflow: hidden;
}

.rkl-modal-dialog_navigation__3E9O8 {
    width: 100%;
    padding: 0 24px;
}

.rkl-modal-dialog_closeModalButton__1I7fi {
    position: absolute;
    right: 15px;
    top: 15px;
}

.rkl-modal-dialog_closeModalButton__1I7fi > svg {
    width: 40px;
    height: 40px;
}

.rkl-modal-dialog_closeModalButton__1I7fi:hover {
    opacity: 0.9;
    cursor: pointer;
}

.rkl-modal-dialog_containerTopViewMobile__3RYqN {
    z-index: 9;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-top: 93px; /* header height */
}

@media (min-width: 320px) {
    .rkl-modal-dialog_dialog__2AwQH {
        width: 100%;
        padding-top: 40px;
    }
}

.transaction-state_item__ADzCS {
    text-align: left;
    margin-bottom: 12px;
}

.transaction-state_itemSuccess__1ayYl {
    text-align: center;
}

.transaction-state_itemHead__1JZ_6 {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.transaction-state_itemHead__1JZ_6 svg path {
    fill: #b87af7;
}

.transaction-state_itemHeadLabel__Z9sEz {
    padding-left: 10px;
}

.transaction-state_itemDescription__1UBv_ {
    display: -webkit-flex;
    display: flex;
    font-size: 14px;
    color: #a3a3a3;
    border-left: 1px solid #494a51;
    margin-left: 32px;
    margin-top: 4px;
    padding: 8px 20px;
}

.transaction-state_tnxHash__q_ki7 {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.transaction-state_itemStatus__3POu- {
    font-size: 14px;
    border-left: 1px solid #494a51;
    margin-left: 32px;
    padding: 8px 20px;
}

.transaction-state_itemStatusLabel__2RQfq {
    padding-right: 24px;
}

/* TBD: need to update and intgrate */
/* FONT */

.styles_fontSizeXLarge__D0eOq {
    font-size: 60px;
    font-weight: 500;
}

.styles_fontSizeExtraLarge__3thge {
    font-size: 40px;
    font-weight: 600;
}

.styles_fontSizeLarge__2t6wr {
    font-size: 32px;
    font-weight: 400;
}

.styles_fontSizeXMedium__3xcag {
    font-size: 24px;
    font-weight: 400;
}

.styles_fontSizeMedium__3Oyzz {
    font-size: 20px;
    font-weight: bold;
}

.styles_fontSizeXSmall__2Jju9 {
    font-size: 16px;
    font-weight: 500;
}

.styles_fontSizeMediumNormal__FjAkc {
    font-size: 20px;
    font-weight: 400;
}

.styles_fontSizeNormal__1u7sB {
    font-size: 16px;
    font-weight: 400;
}

.styles_lineHeightNormal__An4jI {
    line-height: 1.6;
}

.styles_fontColorWhite__TB7Ca {
    color: #fefefe;
}

.styles_fontColorBlack__mvjqD {
    color: #000000;
}

.styles_fontColorGray__QRfIV {
    color: #a6a6a6;
}

.styles_fontColorRose__-oGWN {
    color: #b87af7;
}

.styles_fontColorLightGray__sNUWI {
    color: #c3c3c3;
}

.styles_fontColorYellow___XT4K {
    color: #feda5e;
}

/* BG */

.styles_bgColorBlack__3SAgW {
    background-color: #000000;
}

.styles_bgColorWhite__1RG_F {
    background-color: #ffffff;
}

.styles_bgColorTransparent__1Nuvl {
    background-color: transparent;
}

.styles_bgColorRose__2KBGV {
    background-color: #b87af7;
}

.styles_borderColorBlack__2cHOK {
    border: 1px solid #000000;
}

.styles_textAlignLeft__2zPLK {
    text-align: left;
}

.styles_page__2NHN_ {
    width: 100%;
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_staticPage__1iSSn {
    width: 100%;
    margin-top: 94px;
    min-height: calc(100vh - 94px);
    height: 100%;
    background: #000000;
}

.styles_staticPageInner__22tWc {
    max-width: 800px;
    padding: 24px 24px 48px;
    margin: 0 auto;
}

.styles_staticPageInnerWide__1fuDh {
    width: 100%;
    padding: 24px 32px 48px;
    margin: 0 auto;
}

.styles_pageTitle__aExM- {
    padding: 32px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.styles_contentCenter__24x3d {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_roundBlock__2g-jg {
    position: relative;
    background: #000000;
    border: 1px solid #494a51;
    padding: 24px 0;
    border-radius: 12px;
}

.styles_roundBlockDelimiter__3gPuH {
    width: 100%;
    height: 1px;
    background: #494a51;
}

/* */

.styles_ethInputWrapper__33-eW {
    position: relative;
    width: 60%;
    margin: 0 auto;
}

.styles_ethInputIco__3Z8es {
    position: absolute;
    top: 1px;
    right: 1px;
    padding: 18px 8px;
    z-index: 2;
    width: 96px;
    border-radius: 0 12px 12px 0;
    background: #000000;
}

.styles_ethInputLabel__3x-OH {
    font-size: 14px;
    color: #585858;
}

.styles_ethInput__1mObq {
    position: relative;
    background: #1b1b1b;
    border: 1px solid #8c8c8c;
    color: #fff;
    text-align: right;
    font-size: 38px;
    height: 96px;
    padding: 8px 110px 8px 8px;
    width: 100%;
    border-radius: 12px;
}

.styles_baseInput__1NfRh {
    position: relative;
    background: #1b1b1b;
    border: 1px solid #8c8c8c;
    color: #fff;
    font-size: 18px;
    width: 100%;
    padding: 8px;
    border-radius: 12px;
}

.styles_ethInput__1mObq.styles_error__aKJb8 {
    border-color: #f00;
}

.styles_errorMessage__3gcdF {
    color: #f00;
}

/* GRID */

.styles_flex__1bbAZ {
    display: -webkit-flex;
    display: flex;
}

.styles_gridRow__5uxMZ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.styles_gridCalAlignCenter__7-9V2 {
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_gridRowAlignBaseline__1zTyz {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.styles_spaceBetween__16pL- {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.styles_mbM__1hPch {
    margin-bottom: 12px;
}

.styles_mbMl__pFnx7 {
    margin-bottom: 16px;
}

.styles_mbL__3ZZ4S {
    margin-bottom: 20px;
}

.styles_mbXl__1Wz8B {
    margin-bottom: 24px;
}

.styles_mbXXl__1E996 {
    margin-bottom: 28px;
}

.styles_mbXXXl__zPmIv {
    margin-bottom: 32px;
}

.styles_pxXl__LjFfA {
    padding-left: 24px;
    padding-right: 24px;
}

.styles_gridCal1__XtfMu {
    width: 10%;
}

.styles_gridCal2__1VKR1 {
    width: 20%;
}

.styles_gridCal25__12NC6 {
    width: 25%;
}

.styles_gridCal3__2M5xP {
    width: 30%;
}

.styles_gridCal5__307SQ {
    width: 50%;
}

.styles_gridCal10__1GeFO {
    width: 100%;
}

.styles_gridBlock__33ql9 {
    display: block;
}

.styles_gridSpace__1XD27 {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

@media screen and (max-height: 800px) {
    .styles_fontSizeXLarge__D0eOq {
        font-size: 38px;
    }
    .styles_fontSizeXMedium__3xcag {
        font-size: 20px;
    }
}

@media screen and (max-height: 650px) {
    .styles_fontSizeXLarge__D0eOq {
        font-size: 35px;
    }
    .styles_fontSizeXMedium__3xcag {
        font-size: 18px;
    }
}

@media screen and (max-width: 1150px) {
    .styles_fontSizeMedium__3Oyzz {
        font-size: 16px;
        font-weight: bold;
    }
}

@media screen and (max-width: 900px) {
    .styles_fontSizeXLarge__D0eOq {
        font-size: 42px;
        font-weight: 500;
    }
}

@media screen and (max-width: 576px) {
    .styles_fontSizeXLarge__D0eOq {
        font-size: 26px;
        font-weight: 500;
    }

    .styles_fontSizeExtraLarge__3thge {
        font-size: 36px;
        font-weight: 600;
    }

    .styles_fontSizeLarge__2t6wr {
        font-size: 28px;
        font-weight: 400;
    }

    .styles_fontSizeXMedium__3xcag {
        font-size: 18px;
        font-weight: 400;
    }

    .styles_fontSizeMediumNormal__FjAkc {
        font-size: 13px;
        font-weight: 400;
    }
}

@media screen and (max-height: 600px) {
    .styles_fontSizeXLarge__D0eOq {
        font-size: 18px;
        font-weight: 500;
    }
}

@media screen and (max-width: 280px) {
    .styles_fontSizeXLarge__D0eOq {
        font-size: 18px;
        font-weight: 500;
    }
}


.button {
    padding: 22px 52px 22px 52px;
    border-radius: 60px;
    text-align: center;
    justify-content: center;
    font-weight: 700;
    border: 1px solid transparent;
    cursor: pointer;
    position: relative;
}

.square {
    width: 68px;
    height: 58px;
    border-radius: 12px;
    white-space: normal;
    padding: 18px 14px;
    font-size: var(--font-size-12);
}

.square span {
    flex-direction: column;
}

.small {
    width: 68px;
    height: 34px;
    border-radius: 45px;
    padding: 8px 11px;
    font-size: var(--font-size-14);
}

.medium {
    width: 100px;
    height: 42px;
    border-radius: 45px;
    padding: 8px 11px;
    font-size: var(--font-size-14);
}

.lgDesktop {
    width: 168px;
    height: 68px;
    padding: 18px 32px;
    border-radius: 40px;
    font-size: var(--font-size-20);
}

.medDesktop {
    width: 145px;
    height: 68px;
    padding: 21px 40px;
    border-radius: 40px;
    font-size: var(--font-size-20);
}

.mobile {
    width: 139px;
    height: 64px;
    font-size: var(--font-size-20);
    padding: 20px 37px;
}

.lgMobile {
    width: 171px;
    height: 64px;
    padding: 20px 53px;
    font-size: var(--font-size-20);
}

.twitter {
    width: 171px;
    height: 68px;
    padding: 21px 40px;
    font-size: var(--font-size-20);
}

.twitterButtonIcon{
    margin-left: 10px;
    display: flex;
}

.squared {
    border-radius: 12px;
}

.violetBG {
    background-color: var(--violet-color);
    color: var(--black-color);
}

.whiteBG {
    background-color: var(--white-color);
}

.whiteBG:hover, 
.whiteBG:active {
    background-color: var(--lightGrey-color) !important;
}

.greenBG {
    background-color: var(--green-color);
    color: var(--black-color);
}

.borderOnlyViolet {
    background-color: transparent;
    color: var(--violet-color);
    border: 1px solid var(--violet-color);
}

.loading {
    background-color: var(--white-color);
    color: var(--white-color-2);
}

.borderOnlyWhite {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
}

.button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}

.button:active {
    background-color: var(--white-color);
    color: var(--black-color);
    margin-top: 2px;
    margin-bottom: -2px;
}

.greenBG:hover {
    border: 1px solid var(--white-color);
}

.button:disabled {
    color: black;
    background-color: var(--grey-color);
}

.button:hover:disabled {
    cursor: not-allowed;
}

.button:active:disabled {
    margin-top: 0;
}

.btnSpan {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.decorator {
    padding: 3px 7px;
    border-radius: 40px;
    background-color: var(--black-color);
}

.button:hover:enabled .decorator {
    color: var(--white-color);
}

.fontColorViolet {
    color: var(--violet-color);
}

.fontColorBlack {
    color: var(--black-color);
}

.fontColorGreen {
    color: var(--green-color);
}

.spinnerWrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
}
.spinner {
    border: 4px solid var(--black-color);
    border-top: 4px solid var(--white-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    height: 100%;
    width: 100%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

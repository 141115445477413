.item {
    text-align: left;
    margin-bottom: 12px;
}

.itemSuccess {
    text-align: center;
}

.itemHead {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.itemHead svg path {
    fill: #b87af7;
}

.itemHeadLabel {
    padding-left: 10px;
}

.itemDescription {
    display: flex;
    font-size: 14px;
    color: #a3a3a3;
    border-left: 1px solid #494a51;
    margin-left: 32px;
    margin-top: 4px;
    padding: 8px 20px;
}

.tnxHash {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.itemStatus {
    font-size: 14px;
    border-left: 1px solid #494a51;
    margin-left: 32px;
    padding: 8px 20px;
}

.itemStatusLabel {
    padding-right: 24px;
}

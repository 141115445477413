.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalDialog {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    border-radius: 23px;
    height: auto;
    text-align: center;
    background: var(--black-color);
    margin: 0 12px;
    border: 1px solid var(--grey-color);
    position: relative;
    color: var(--white-color);
}

.modalDialogContent {
    padding: 32px 24px;
}

.header {
    margin-bottom: 8px;
}

.text {
    font-size: var(--xregular-font-size);
    font-weight: bold;
    margin-bottom: 12px;
}

.errorContent {
    background: #1e1e1e;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 23px;
    font-size: 14px;
}

.buttons button {
    margin: 0 5px;
}

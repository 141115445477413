@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Inter', sans-serif;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html,
body,
#__next {
    height: 100%;
    font-family: 'Inter', sans-serif;
}

div,
span,
input,
textarea,
select {
    box-sizing: border-box;
}

body {
    height: 100%;
    width: 100%;
    background-color: #fff;
}

#__next {
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.body-overflow {
    overflow: hidden;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}